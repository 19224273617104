// routes
import { PATH_DASHBOARD } from "src/routes/paths";

// icons
// import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
// import AccountTreeIcon from "@mui/icons-material/AccountTree";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import PagesIcon from "@mui/icons-material/Pages";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";

// components
import SvgIconStyle from "src/components/SvgIconStyle";

// ----------------------------------------------------------------------

const getIcon = (name) => (
  <SvgIconStyle
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);

const ICONS = {
  user: getIcon("ic_user"),
  ecommerce: getIcon("ic_ecommerce"),
  analytics: getIcon("ic_analytics"),
  dashboard: getIcon("ic_dashboard"),
  googleMyBusiness: getIcon("gmb"),
  whatsapp: getIcon("whatsapp"),
  googleAds: getIcon("google-ads"),
  googleWorkspace: getIcon("google-icon"),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    // subheader: "general v3.4.0",
    items: [
      {
        title: "WhatsApp",
        path: PATH_DASHBOARD.whatsapp.root,
        icon: ICONS.whatsapp,
        children: [
          {
            title: "Dashboard",
            path: PATH_DASHBOARD.whatsapp.home,
            icon: <HomeRoundedIcon />,
          },
          {
            title: "Broadcast",
            path: PATH_DASHBOARD.whatsapp.broadcast,
            children: [
              {
                title: "Create Broadcast",
                path: PATH_DASHBOARD.whatsapp.createBroadcast,
              },
              {
                title: "Broadcast Report",
                path: PATH_DASHBOARD.whatsapp.broadcastReport,
              },
              {
                title: "Broadcast Journey",
                path: PATH_DASHBOARD.whatsapp.broadcastJourney.list,
              },
            ],
          },
          {
            title: "Templates",
            path: PATH_DASHBOARD.whatsapp.templates,
          },
          {
            title: "Bot",
            path: PATH_DASHBOARD.whatsapp.bot,
          },
          {
            title: "Team Inbox",
            path: PATH_DASHBOARD.whatsapp.teamInbox,
          },
          {
            title: "Flows",
            path: PATH_DASHBOARD.whatsapp.flows,
          },
          {
            title: "Insights",
            path: PATH_DASHBOARD.whatsapp.insightsMsgLimit,
          },
          {
            title: "Integration",
            path: PATH_DASHBOARD.whatsapp.integration,
          },
          {
            title: "e-Commerce",
            path: PATH_DASHBOARD.whatsapp.eCommerce,
            children: [
              {
                title: "Catalogue",
                path: PATH_DASHBOARD.whatsapp.catalogue,
              },
              {
                title: "Orders",
                path: PATH_DASHBOARD.whatsapp.orders,
              },
            ],
          },
          // {
          //   title: "Dialogflow",
          //   path: PATH_DASHBOARD.whatsapp.dialogflowAnalytics,
          // },
          // {
          //   title: "Insights",
          //   path: PATH_DASHBOARD.whatsapp.insights,
          // },
          // {
          //   title: "insights",
          //   path: PATH_DASHBOARD.whatsapp.insightsMsgLimit,
          // },
          {
            title: "Account",
            path: PATH_DASHBOARD.whatsapp.account,
            children: [
              {
                title: "Setup",
                path: PATH_DASHBOARD.whatsapp.generalSettings,
              },

              {
                title: "Billing",
                path: PATH_DASHBOARD.whatsapp.billing,
                children: [
                  {
                    title: "Wallet",
                    path: PATH_DASHBOARD.whatsapp.wallet,
                  },
                  {
                    title: "Subscription",
                    path: PATH_DASHBOARD.whatsapp.subscription,
                  },
                ],
              },
              {
                title: "Developers",
                path: PATH_DASHBOARD.whatsapp.developers,
                children: [
                  {
                    title: "API Logs",
                    path: PATH_DASHBOARD.whatsapp.apiLogs,
                  },
                ],
              },
              {
                title: "Settings",
                path: PATH_DASHBOARD.whatsapp.settings,
                children: [
                  {
                    title: "Profile",
                    path: PATH_DASHBOARD.whatsapp.profile,
                  },

                  {
                    title: "Config Reports",
                    path: PATH_DASHBOARD.whatsapp.reportSettings,
                  },
                  {
                    title: "Unsubscribers List",
                    path: PATH_DASHBOARD.whatsapp.unsubscribersList,
                  },
                ],
              },
            ],
          },
        ],
      },
      // {
      //   title: "Chat App",
      //   path: "/dashboard/chat-app",
      //   icon: <MarkUnreadChatAltIcon />,
      //   children: [
      //     {
      //       title: "Bot",
      //       path: PATH_DASHBOARD.chatApp.bot,
      //     },
      //     {
      //       title: "Chat Report",
      //       path: "/dashboard/chat-app/report",
      //     },
      //     {
      //       title: "Chat Analytics",
      //       path: "/dashboard/chat-app/analytics",
      //     },
      //     {
      //       title: "Settings",
      //       path: "/dashboard/chat-app/settings",
      //     },
      //   ],
      // },
      {
        title: "Social Post",
        path: "/dashboard/social-post",
        icon: <PagesIcon />,
        children: [
          {
            title: "New Post",
            path: PATH_DASHBOARD.socialPost.newPost,
          },
          {
            title: "All Posts",
            path: PATH_DASHBOARD.socialPost.allPosts,
          },
          {
            title: "Analytics",
            path: PATH_DASHBOARD.socialPost.analytics,
          },
          {
            title: "Settings",
            path: PATH_DASHBOARD.socialPost.facebookAndInstagram,
          },
        ],
      },
      {
        title: "Google my business",
        path: "/dashboard/google-my-business",
        icon: ICONS.googleMyBusiness,
        children: [
          {
            title: "Reviews",
            path: PATH_DASHBOARD.googleMyBusiness.reviews.root,
          },
          {
            title: "Business messsages",
            children: [
              {
                title: "Locations",
                path: "/dashboard/google-my-business/locations",
              },
              {
                title: "Analytics",
                path: "/dashboard/google-my-business/business-messages-analytics",
              },
            ],
          },
          {
            title: "Geo grid",
            path: "/dashboard/google-my-business/geo-grid",
          },
          {
            // title: "Update Profile",
            title: "Profile Info",
            path: PATH_DASHBOARD.googleMyBusiness.profileInfo,
          },
          {
            // title: "Upload Photos",
            title: "Photos",
            path: PATH_DASHBOARD.googleMyBusiness.uploadPhotos,
          },
          {
            title: "Add Update",
            path: "/dashboard/google-my-business/add-update",
          },
          {
            title: "Services",
            path: "/dashboard/google-my-business/services",
          },
          {
            title: "Schema Info",
            path: "/dashboard/google-my-business/schema-info",
          },
          // {
          //   title: "Google Workspace",
          //   path: PATH_DASHBOARD.googleMyBusiness.googleWorkspace,
          // },
          {
            title: "Settings",
            path: "/dashboard/google-my-business/settings",
            children: [
              {
                title: "General Settings",
                path: "/dashboard/google-my-business/settings/general",
              },
              {
                title: "Manage Locations",
                path: "/dashboard/google-my-business/settings/manage-locations",
              },
              {
                title: "Google Maps API Keys",
                path: "/dashboard/google-my-business/settings/google-maps-api-keys",
              },
              {
                title: "Review Report",
                path: "/dashboard/google-my-business/settings/gmb/report-settings",
              },
              {
                title: "Geogrid Report",
                path: "/dashboard/google-my-business/settings/geogrid/report-settings",
              },
              {
                title: "Chat Report",
                path: "/dashboard/google-my-business/settings/chat/report-settings",
              },
              // {
              //   title: "Manage API keys",
              //   path: "/dashboard/google-my-business/settings/manage-api-keys",
              // },
            ],
          },
          // {
          //   title: "Location custom fields",
          //   path: "/dashboard/google-my-business/location-custom-fields",
          // },
          // {
          //   title: "New Post",
          //   path: "/dashboard/google-my-business/new-post",
          // },

          // {
          //   title: "Posts",
          //   path: "/dashboard/google-my-business/posts",
          // },
          // {
          //   title: "Photos",
          //   path: "/dashboard/google-my-business/photos",
          // },
          // {
          //   title: "Products",
          //   path: "/dashboard/google-my-business/products",
          // },
          // {
          //   title: "Services",
          //   path: "/dashboard/google-my-business/services",
          // },
          // {
          //   title: "Insights",
          //   path: "/dashboard/google-my-business/insights",
          // },
          // {
          //   title: "Location POC Details",
          //   path: "/dashboard/google-my-business/location-poc-details",
          // },
        ],
      },
      {
        title: "Google Workspace",
        path: PATH_DASHBOARD.googleWorkspace.root,
        icon: ICONS.googleWorkspace,
      },
      {
        title: "Google Ads",
        path: "/dashboard/google-ads",
        icon: ICONS.googleAds,
        children: [
          { title: "Overview", path: PATH_DASHBOARD.googleAds.overview },
          // { title: "Settings", path: PATH_DASHBOARD.googleAds.settings },
        ],
      },
      {
        title: "Assets Manager",
        path: "/dashboard/assets-manager",
        icon: <ManageAccountsIcon />,
        children: [
          {
            title: "URL Manager",
            path: PATH_DASHBOARD.assetsManager.url,
          },
          {
            title: "Media Manager",
            path: PATH_DASHBOARD.assetsManager.media,
          },
          {
            title: "Custom Domain Setup",
            path: PATH_DASHBOARD.assetsManager.domainSetup,
          },
        ],
      },
      {
        title: "Organisation",
        path: "/dashboard/organisation",
        icon: <CorporateFareIcon />,
        children: [
          { title: "Users", path: PATH_DASHBOARD.organisation.users.list },
          {
            title: "Organisation units",
            path: PATH_DASHBOARD.organisation.units,
          },
          {
            title: "Custom Fields",
            path: PATH_DASHBOARD.organisation.customFields,
          },
        ],
      },
      // {
      //   title: "User Management",
      //   path: "/dashboard/users",
      //   icon: ICONS.user,
      //   children: [
      //     { title: "Users List", path: "/dashboard/users/users-list" },
      //     { title: "Create User", path: "/dashboard/users/create-user" },
      //   ],
      // },
      // {
      //   title: "Organisation units",
      //   path: "/dashboard/org-units",
      //   icon: <AccountTreeIcon />,
      // },
    ],
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'management',
  //   items: [
  //     {
  //       title: 'user',
  //       path: '/dashboard/user',
  //       icon: ICONS.user,
  //       children: [
  //         { title: 'Four', path: '/dashboard/user/four' },
  //         { title: 'Five', path: '/dashboard/user/five' },
  //         { title: 'Six', path: '/dashboard/user/six' },
  //       ],
  //     },
  //   ],
  // },
];

export default navConfig;
